<template>
	<el-dialog :title="!dataForm.recId ? '通讯录添加' : '通讯录修改'" :close-on-click-modal="false" :visible.sync="visible"
		width="910px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="所在单位" prop="companyNo" class="item">
						<el-select class="selItemInput" v-model="dataForm.companyNo" placeholder="请选择">
							<el-option v-for="item in companyInfoList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="所在部门" prop="personDepartment" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.personDepartment"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="职务" prop="personDuty" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.personDuty"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="姓名" prop="personName" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.personName"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="电话" prop="personTel" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.personTel"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="邮箱" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.personEmail"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="备注" prop="remark" class="item">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="5" v-model="dataForm.remark"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "customer-add-or-update",
	data() {
		return {
			visible: true,
			form: {},
			action: "",
			companyInfoList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				recId: "",
				companyNo: "",
				personDepartment: "",
				personDuty: "",
				personEmail: "",
				personName: "",
				personTel: "",
				remark: ""
			},
			dataRule: {
				companyNo: [
					{ required: true, message: "所在单位不能为空！", trigger: "blur" }
				],
				personName: [
					{ required: true, message: "人员姓名不能为空！", trigger: "blur" }
				],
				personTel: [
					{ required: true, message: "联系电话不能为空！", trigger: "blur" }
				],
				personEmail: [
					{ required: true, message: "电子邮箱不能为空！", trigger: "blur" },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: "blur" }
				]
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.getCompanyInfo();
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				recId: "",
				companyNo: "",
				personDepartment: "",
				personDuty: "",
				personEmail: "",
				personName: "",
				personTel: "",
				remark: ""
			};
			this.dataForm.id = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getCompanyInfo() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getCompanyInfo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.companyInfoList = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/addressbook/info/" + this.dataForm.id,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
				}
			});
		},
		dataFormSubmit() {
			if (this.dataForm.companyNo == null || this.dataForm.companyNo == "") {
				this.$message.error("所在单位不能为空！");
				return;
			}
			if (this.dataForm.personName == null || this.dataForm.personName == "") {
				this.$message.error("人员姓名不能为空！");
				return;
			}
			if (this.dataForm.personTel == null || this.dataForm.personTel == "") {
				this.$message.error("联系电话不能为空！");
				return;
			}
			// if (this.dataForm.personEmail == null || this.dataForm.personEmail == "") {
			// 	this.$message.error("电子邮箱不能为空！");
			// 	return;
			// } else {
			// 	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 这里使用了常规的电子邮件格式正则表达式

			// 	if (!regex.test(this.dataForm.personEmail)) {
			// 		this.$message.error("请输入正确的邮箱地址");
			// 		return;
			// 	}
			// }

			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/addressbook/save";
					if (this.dataForm.recId && this.dataForm.recId != 0) {
						_url = "/business/addressbook/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 694px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}


.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
